<template>
  <div class="allmsg" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="msgvoice">
      待开票金额 <span>{{ kaimoney }}</span> 元
    </div>
    <div class="writevoice">
      <div class="voicetype">
        <div>开票类型</div>
        <div>
          <van-checkbox
            @click="clickcheck3"
            checked-color="#FBA110"
            v-model="checked3"
            >专用发票</van-checkbox
          >
        </div>
        <div>
          <van-checkbox
            @click="clickcheck4"
            checked-color="#FBA110"
            v-model="checked4"
            >普通发票</van-checkbox
          >
        </div>
      </div>
      <div class="voicetype">
        <div>抬头类型</div>
        <div>
          <van-checkbox
            @click="clickcheck1"
            checked-color="#FBA110"
            v-model="checked1"
            >企业单位</van-checkbox
          >
        </div>
        <!-- <div>
          <van-checkbox
            @click="clickcheck2"
            checked-color="#FBA110"
            v-model="checked2"
            >个人/非企业单位</van-checkbox
          >
        </div> -->
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>开票金额</div>
        <div><input v-model="money" placeholder="100元起开" /></div>
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>公司名称</div>
        <div>
          <input
            :disabled="iswritemsg"
            v-model="invoice_title"
            placeholder="请输入公司名称"
          />
        </div>
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>公司税号</div>
        <div>
          <input
            :disabled="iswritemsg"
            v-model="invoice_num"
            placeholder="请输入公司税号"
          />
        </div>
      </div>
      <div v-show="checked3 == true" class="voicetype">
        <div><span class="span1">*</span>公司地址</div>
        <div>
          <input v-model="enterprise_addr" placeholder="请输入公司地址" />
        </div>
      </div>
      <div v-show="checked3 == true" class="voicetype">
        <div><span class="span1">*</span>公司电话</div>
        <div>
          <input v-model="enterprise_tel" placeholder="请输入公司电话" />
        </div>
      </div>
      <div v-show="checked3 == true" class="voicetype">
        <div><span class="span1">*</span>开户行</div>
        <div><input v-model="bank" placeholder="请输入开户行" /></div>
      </div>
      <div v-show="checked3 == true" class="voicetype">
        <div><span class="span1">*</span>银行账号</div>
        <div><input v-model="bank_card_id" placeholder="请输入银行账号" /></div>
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>开票类目</div>
        <div>
          <input
            @click="clickbilling"
            readonly
            v-model="billing_cate"
            placeholder="请输入开票类目"
          />
        </div>
      </div>
      <div class="voicetype">
        <div>发票备注</div>
        <div><input v-model="note" placeholder="请输入发票备注" /></div>
      </div>
    </div>

    <div class="msgvoice">接收方式</div>
    <div class="writevoice">
      <div class="voicetype">
        <div><span class="span1">*</span>收件人姓名</div>
        <div><input v-model="addressee" placeholder="请输入收件人姓名" /></div>
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>收件人手机</div>
        <div>
          <input v-model="address_phone" placeholder="请输入收件人手机" />
        </div>
      </div>
      <div class="voicetype">
        <div><span class="span1">*</span>收件人地址</div>
        <div><input v-model="address" placeholder="请输入收件人地址" /></div>
      </div>
      <div class="voicetype">
        <div>邮寄类型</div>
        <div class="allyouji">
          <div
            @click="clickwuliu1"
            class="youji youji1"
            :id="wuliu1 == true ? 'selectdiv' : ''"
          >
            <img src="../../assets/sr.png" />
            <div>顺丰快递</div>
          </div>
          <div
            @click="clickwuliu2"
            class="youji youji2"
            :id="wuliu2 == true ? 'selectdiv' : ''"
          >
            <img src="../../assets/ems.png" />
            <div>EMS快递</div>
          </div>
        </div>
      </div>
      <div class="voicetype">
        <div>快递费用</div>
        <div class="dao1" id="selectdiv">
          <div class="dao2"></div>
          <div>到付</div>
          <div></div>
        </div>
      </div>
    </div>

    <p class="voicep">
      快递发票易名不提供，需要快递人员索取，快递费用，以快递收取为准
    </p>
    <button @click="upvoicemsg" class="bton bt">提交开票</button>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      iswritemsg: true,
      msg: "申请开票",
      checked1: true,
      checked2: false,
      checked3: true,
      checked4: false,
      wuliu1: true, //默认显示顺丰快递
      wuliu2: false, //点击ems快递
      invoice_title: "", //公司抬头
      invoice_num: "", //发票税号
      invoice_type: 2, //发票种类 1-普通发票 2-增值税发票
      invoice_type2: 2, //发票状态 1-个人 2-企业
      invoice_type3: "", //发票类型1-电子发票 2-纸质发票
      bank_card_id: "", //银行卡号
      bank: "", //开户银行
      enterprise_tel: "", //企业电话
      enterprise_addr: "", //企业地址
      billing_cate: "", //开票类目
      addressee: "", //收件人
      address_phone: "", //收件人手机
      address: "", //收件人地址
      mail_type: "1", //邮件类型1-顺丰 2-ems
      mail_cost: "1", //1-到付
      note: "", //开票说明
      money: "", //开票金额
      kaimoney: "",
      allvoicemsg: "", //已存储的发票信息
      show: false,
      actions: [{ name: "服务费" }, { name: "咨询费" }],
    };
  },
  created() {
    //获取发票已填写的信息
    this.getvoicemsg();
  },
  mounted() {
    this.$http
      .post("/firm/v1/userinfo/billable_money", {
        reqType: "userinfo",
      })
      .then((res) => {
        this.kaimoney = JSON.parse(res.data).data.billable_money;
      });
  },
  methods: {
    /**
     * 选择类目返回的值为
     */
    onSelect(msg) {
      this.billing_cate = msg.name;
      this.show = false;
    },
    /**
     * 选择开票类目
     */
    clickbilling() {
      this.show = true;
    },
    clickcheck1() {
      this.checked2 = false;
      this.checked1 = true;
      this.invoice_type2 = 2;
    },
    clickcheck2() {
      this.checked1 = false;
      this.checked2 = true;
      this.invoice_type2 = 1;
    },
    clickcheck3() {
      this.checked4 = false;
      this.checked3 = true;
      this.invoice_type = 2;
    },
    clickcheck4() {
      this.checked3 = false;
      this.checked4 = true;
      this.invoice_type = 1;
    },

    //点击顺丰快递
    clickwuliu1() {
      this.mail_type = 1;
      this.wuliu1 = true;
      this.wuliu2 = false;
    },
    //点击ems快递
    clickwuliu2() {
      this.mail_type = 2;
      this.wuliu1 = false;
      this.wuliu2 = true;
    },

    /**
     * 填充数据
     */
    setvoicemsg(msg) {
      // (msg == null
      //     ? this.enterprise = localStorage.getItem("enterprise")
      //     : msg.invoice_title); //公司名称
      if (msg == null) {
        this.invoice_title = localStorage.getItem("enterprise");
        this.invoice_num = msg.invoice_num;
        return;
      }

      this.invoice_title = msg.invoice_title; //公司名称
      this.invoice_num = msg.invoice_num; //发票税号
      (this.invoice_type = msg.invoice_type), //发票种类 1-普通发票 2-增值税发票
        (this.invoice_type2 = msg.invoice_type2), //发票状态 1-个人 2-企业
        (this.invoice_type3 = msg.invoice_type3), //发票类型1-电子发票 2-纸质发票
        (this.bank_card_id = msg.bank_card_id), //银行卡号
        (this.bank = msg.bank), //开户银行
        (this.enterprise_tel = msg.enterprise_tel), //企业电话
        (this.enterprise_addr = msg.enterprise_addr), //企业地址
        (this.billing_cate = msg.billing_cate), //开票类目
        (this.addressee = msg.addressee), //收件人
        (this.address_phone = msg.address_phone), //收件人手机
        (this.address = msg.address), //收件人地址
        (this.mail_type = msg.mail_type), //邮件类型1-顺丰 2-ems
        (this.mail_cost = msg.mail_cost), //1-到付
        (this.note = msg.note); //开票说明
      this.money = msg.money; //金额
    },

    /**
     * 获取发票已填写信息
     */
    getvoicemsg() {
      this.$http
        .post("/firm/v1/userinfo/billable_info", {
          reqType: "userinfo",
          invoice_type: 2,
        })
        .then((res) => {
          this.allvoicemsg = JSON.parse(res.data).data;
          this.setvoicemsg(this.allvoicemsg);
        });
    },

    //判断数据是否合规
    iscompliance() {
      if (this.money == "") {
        this.$toast("请输入开票金额");
        return false;
      }
      if (this.money < 100) {
        this.$toast("开票金额最低100元");
        return false;
      }

      if (this.invoice_title == "") {
        this.$toast("请输入公司名称");
        return false;
      }
      if (this.invoice_num == "") {
        this.$toast("请输入公司税号");
        return false;
      }
      if (this.billing_cate == "") {
        this.$toast("请输入开票类目");
        return false;
      }
      if (this.addressee == "") {
        this.$toast("请输入收件人姓名");
        return false;
      }
      if (this.address_phone == "") {
        this.$toast("请输入收件人手机");
        return false;
      }
      if (this.address == "") {
        this.$toast("请输入收件人地址");
        return false;
      }

      if (this.enterprise_addr == "" && this.checked3 == true) {
        this.$toast("请输入公司地址");
        return false;
      }
      if (this.enterprise_tel == "" && this.checked3 == true) {
        this.$toast("请输入公司电话");
        return false;
      }
      if (this.bank == "" && this.checked3 == true) {
        this.$toast("请输入开户行");
        return false;
      }
      if (this.bank_card_id == "" && this.checked3 == true) {
        this.$toast("请输入银行账号");
        return false;
      }

      return true;
    },

    //提交发票数据
    upvoicemsg() {
      if (!this.iscompliance()) return;
      this.$toast.loading();
      this.$http
        .post("/firm/v1/userinfo/apply_billable", {
          reqType: "userinfo",
          invoice_title: this.invoice_title,
          invoice_num: this.invoice_num,
          invoice_type: this.invoice_type,
          invoice_type2: this.invoice_type2,
          invoice_type3: this.invoice_type3,
          bank_card_id: this.bank_card_id,
          bank: this.bank,
          enterprise_tel: this.enterprise_tel,
          enterprise_addr: this.enterprise_addr,
          billing_cate: this.billing_cate,
          addressee: this.addressee,
          address_phone: this.address_phone,
          address: this.address,
          mail_type: this.mail_type,
          mail_cost: this.mail_cost,
          note: this.note,
          money: this.money,
        })
        .then((res) => {
          this.$toast("提交成功");
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style scoped>
input[disabled] {
  background-color: #ffffff;
}
.span1 {
  color: #ff2b00;
}
.allmsg {
  padding-bottom: 1rem;
}
#selectdiv {
  border: 1px solid #ff2b00;
  background-color: #fff6ee;
  color: #222222;
}
.voicep {
  font-size: 0.18rem;
  line-height: 0.38rem;
  color: #ff2b00;
  margin: 0.16rem 0.34rem;
}
.bt {
  margin-top: 1.5rem;
}
.allyouji {
  display: flex;
  align-items: center;
}
.youji {
  display: flex;
  align-items: center;
  color: #5b6574;
  border: 1px solid #616a78;
  min-width: 2rem;
  justify-content: center;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.youji1 img {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.1rem;
}
.youji2 img {
  width: 0.67rem;
  height: 0.19rem;
  margin-right: 0.1rem;
}
.dao1 {
  display: flex;
  align-items: center;
  border: 1px solid #616a78;
  padding: 0.1rem 0.3rem;
}
.dao2 {
  width: 0.13rem;
  height: 0.13rem;
  border-radius: 50%;
  border: 0.1rem solid red;
  margin-right: 0.1rem;
}
input {
  text-align: right;
  border: none;
}
textarea {
  border-color: #ebeef5;
  border-radius: 0.2rem;
  padding: 0.1rem;
}
.writevoice {
  background-color: #ffffff;
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.msgvoice span {
  color: red;
  font-weight: bold;
}
.msgvoice {
  margin-top: 0.46rem;
  margin-bottom: 0.21rem;
  margin-left: 0.34rem;
  font-size: 0.28rem;
  color: #616b80;
  line-height: 0.4rem;
}
.voicetype {
  display: flex;
  justify-content: space-between;
  padding: 0.22rem 0.34rem;
  border-bottom-color: #ebeef5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.voicetype div:first-child {
  color: #616b80;
}
</style>